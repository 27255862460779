<template>
  <span class="">
    <slot v-bind="{toggle}"></slot>

    <b-modal v-model="show" :title="title">
      <div class="form-row">
        <sgv-input-text 
          class="col-12"
          label="ชื่อ"
          v-model="formData.name"
          :validations="[
            {text: 'required!', value: $v.formData.name.$dirty && $v.formData.name.$error}
          ]">
        </sgv-input-text>
      </div>
      
      <template slot="modal-footer">
        <button 
          class="btn btn-success" 
          @click="createFolder" 
          v-if="folderId == 0">
          เพิ่ม
        </button>

        <button 
          class="btn btn-warning mr-2" 
          @click="updateFolder" 
          v-if="folderId != 0">
          แก้ไข
        </button>

        <button 
          class="btn btn-danger" 
          @click="destroyFolder" 
          v-if="folderId != 0">
          ลบ
        </button>
      </template>
    </b-modal>
  </span>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { 
  DETAIL_FOLDER,
  CREATE_FOLDER,
  UPDATE_FOLDER,
  DESTROY_FOLDER,
} from './graph/folder'

export default {
  props: {
    parentId: {
      type: Number,
      required: false
    },
    folderId: {
      type: Number,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    assetType: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      show: false,
      formData: {
        name: ''
      }
    }
  },
  validations: {
    formData: {
      name: { required },
    }
  },
  computed: {
    title () {
      if (this.folderId > 0) {
        return this.formData.name
      }
      return 'สร้างแฟ้ม' 
    }
  },
  methods: {
    fetchDetail () {
      this.$apollo.query({
        query: DETAIL_FOLDER(this.templateType),
        variables: {
          assetType: this.assetType,
          folderId: this.folderId
        },
        fetchPolicy: 'no-cache'
      })
      .then(res => {
        this.formData.name = res.data.folder.name
      })
    },
    createFolder() {
      this.$v.$touch()
      if (this.$v.$invalid) return

      this.$apollo.mutate({
        mutation: CREATE_FOLDER(this.templateType),
        variables: {
          assetType: this.assetType,
          input: {
            name: this.formData.name,
            parentId: this.parentId
          }
        }
      }).then(() => {
        this.$emit('change', null)
        this.show = false
        this.$v.$reset()
      }).catch(this.$toasted.global.error)
    },
    updateFolder() {
      this.$v.$touch()
      if (this.$v.$invalid) return

      this.$apollo.mutate({
        mutation: UPDATE_FOLDER(this.templateType),
        variables: {
          assetType: this.assetType,
          folderId: this.folderId,
          input: {
            name: this.formData.name
          }
        }
      }).then(() => {
        this.$emit('change', null)
        this.show = false
        this.$v.$reset()
      }).catch(this.$toasted.global.error)
    },
    destroyFolder() {
      this.$apollo.mutate({
        mutation: DESTROY_FOLDER(this.templateType),
        variables: {
          assetType: this.assetType,
          folderId: this.folderId,
        }
      }).then(() => {
        this.$emit('change', null)
        this.show = false
        this.$v.$reset()
      }).catch(this.$toasted.global.error)
    },
    toggle () {
      this.show = !this.show
    }
  },
  watch: {
    show (value) {
      if (value) {
        if (this.folderId > 0) {
          this.fetchDetail()
        } else {
          this.formData.name = ''
        }
      }
    }
  }
}
</script>

<style lang="css" scoped>
</style>
