<template>
  <div>
    <CommandFolder 
      title="Move Folder"
      :templateType="templateType"
      :assetType="assetType"
      :assetIds="assetIds"
      @change="emitChange">
      <template v-slot="{toggle}">
        <button 
          :disabled="assetIds.length === 0"
          type="button" 
          class="btn btn-warning" 
          @click="toggle">
          Move Folder
        </button>
      </template>
    </CommandFolder>
  </div>
</template>

<script>
import CommandFolder from './CommandFolder.vue'

export default {
  props: {
    templateType: {
      type: String,
      required: true,
    },
    assetType: {
      type: String,
      required: true
    },
    assetIds: {
      type: Array,
      required: false,
      default () {
        return []
      }
    }
  },
  methods: {
    emitChange () {
      this.$emit('change', null)
    }
  },
  components: {
    CommandFolder
  }
}
</script>

<style scoped>

</style>