<template>
  <div class="card pointer" @click="emitParentId">
    <div class="card-body">
      <span class="text-warning mr-2">
        <fa icon="folder"></fa>
      </span>
      <span class="card-text">
        {{ folder.name }}
      </span>
      <span class="text-warning float-right">
        <FolderForm 
          :folderId="folder.id"
          :templateType="templateType" 
          :assetType="assetType"
          @change="emitChange">
          <template slot-scope="{toggle}">
            <span
              class="text-warning pointer px-2"
              @click.prevent.stop="toggle">
              <fa icon="pencil-alt"></fa>
            </span>
          </template>
        </FolderForm>
      </span>
    </div>
  </div>
</template>

<script>
import FolderForm from './FolderForm.vue'

export default {
  props: {
    folder: {
      type: Object,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    assetType: {
      type: String,
      required: true
    },
  },
  methods: {
    emitParentId () {
      this.$emit('parentId', this.folder.id)
    },
    emitChange () {
      this.$emit('change', null)
    }
  },
  components: {
    FolderForm
  }
}
</script>

<style lang="css" scoped>
</style>