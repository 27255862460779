<template>
  <div class="row">
    <AssetUploader
      class="col-12"
      :templateType="templateType"
      :assetType="assetType"
      :folderId="folderId"
      @change="refetch">
    </AssetUploader>

    <div 
      class="col-6 col-sm-4 col-md-3"
      v-for="(item, i) in assets" 
      :key="i"
      @click="selectedAsset(item.id)">
      <div :class="onSelectStyle(item.id)">
        <img 
          v-if="isImage(item.mimeType)"
          :src="item.url" 
          class="img-fluid" 
          :alt="item.filename">
        <div style="word-wrap: break-word;">
          {{item.filename}}
        </div>
        <div class="mt-1 mb-4">
          <span
            style="font-size: 20px;"
            class="mr-2"
            @click.prevent.stop="download(item.id, item.filename)">
            <fa icon="download" class="text-primary pointer"></fa>
          </span>

          <span
            @click.prevent.stop="getMarkdown(item.filename, item.url)"
            style="font-size: 20px;"
            class="mr-2">
            <fa :icon="['fab', 'markdown']" class="text-warning pointer"></fa>
          </span>

          <span
            style="font-size: 20px;"
            class="float-right">
            <fa
              icon="trash"
              class="text-danger pointer"
              @dblclick.prevent.stop="deleteAsset(item.id)">
            </fa>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { 
  LIST_ASSET,
  DOWNLOAD_ASSET,
  DESTROY_ASSET,
} from './graph/asset'
import AssetUploader from './AssetUploader.vue'


export default {
  props: {
    folderId: {
      type: Number,
      required: false
    },
    templateType: {
      type: String,
      required: true
    },
    assetType: {
      type: String,
      required: true
    },
    selected: {
      type: Array,
      required: false,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      assets: []
    }
  },
  apollo: {
    assets: {
      query () {
        return LIST_ASSET(this.templateType)
      },
      variables () {
        return {
          assetType: this.assetType,
          q: {
            folderId: this.folderId,
            orderBy: 'filename'
          }
        }
      },
      fetchPolicy: 'no-cache'
    }
  },
  methods: {
    selectedAsset (assetId) {
      const arr = [...this.selected]
      const index = arr.findIndex(v => v === assetId)

      if (index === -1) arr.push(assetId)
      else arr.splice(index, 1)

      this.$emit('update:selected', arr)
    },
    onSelectStyle (assetId) {
      if (!this.selected.includes(assetId)) return ''

      return 'p-2 bg-primary'
    },
    refetch () {
      this.$apollo.queries.assets.refetch()
    },
    isImage (mimeType) {
      return mimeType.includes('image/')
    },
    deleteAsset (assetId) {
      this.$apollo.mutate({
        mutation: DESTROY_ASSET(this.templateType),
        variables: {
          assetType: this.assetType,
          assetId
        }
      })
      .then(() => {
        this.$toasted.global.success("ลบสำเร็จ")
        this.refetch()
      }).catch(err => {
        this.$toasted.global.error(err)
      })
    },
    download (assetId, filename) {
      this.$apollo.query({
        query: DOWNLOAD_ASSET(this.templateType),
        variables: {
          assetType: this.assetType,
          assetId
        },
        fetchPolicy: 'no-cache'
      })
      .then(res => {
        return axios.get(res.data.url, {responseType: 'blob'})
      })
      .then(res => {
        const a = window.document.createElement('a')
        a.href = window.URL.createObjectURL(res.data)
        a.download = filename
        a.click()
      })
      .catch(() => {
        this.$toasted.global.error("ไม่สามารถโหลดได้")
      })
    },
    getMarkdown (filename, url) {
      this.$copyText(`![${filename}](${url})`)
    },
  },
  components: {
    AssetUploader
  }
}
</script>