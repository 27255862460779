import gql from 'graphql-tag'

export const LIST_ASSET = (templateType) => gql`query LIST_ASSET ($assetType: String!, $q: ${templateType}FilterInput) {
  assets: list${templateType} (assetType: $assetType, q: $q) {
    id type acl filepath filename mimeType
    size width height
    folderId folder {id name}
    url
  }
}`

export const DOWNLOAD_ASSET = (templateType) => gql`query DOWNLOAD_ASSET ($assetType: String!, $assetId: Int!) {
  url: download${templateType} (assetType: $assetType, assetId: $assetId)
}`


export const CREATE_ASSET = (templateType) => gql`mutation CREATE_ASSET ($assetType: String!, $input: ${templateType}CreateInput!) {
  createAsset: create${templateType} (assetType: $assetType, input: $input) {
    id type acl filepath filename mimeType
    size width height
    folderId url fields
  }
}`

export const DESTROY_ASSET = (templateType) => gql`mutation DESTROY_ASSET ($assetType: String!, $assetId: Int!) {
  destroyAsset: destroy${templateType} (assetType: $assetType, assetId: $assetId)
}`

export const MOVE_ASSET = (templateType) => gql`mutation MOVE_ASSET ($assetType: String!, $assetIds: [Int!]!, $folderId: Int) {
  moveAsset: move${templateType} (assetType: $assetType, assetIds: $assetIds, folderId: $folderId)
}`