<template>
  <div class="col-12">
    <div
      class="dropbox mb-3"
      @click="$refs.fileInput.click()">
      <p class="text-success">
        <slot>เพิ่มไฟล์</slot>
      </p>
      <input
        type="file"
        ref="fileInput"
        class="input-file"
        multiple
        @change="onFileChange"/>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Compressor from 'compressorjs'
import { CREATE_ASSET } from './graph/asset'

export default {
  props: {
    folderId: {
      type: Number,
      required: false
    },
    acl: {
      type: String,
      required: false,
      default: 'public-read'
    },
    isCompress: {
      type: Boolean,
      required: false
    },
    templateType: {
      type: String,
      required: true
    },
    assetType: {
      type: String,
      required: true
    }
  },
  methods: {
    onFileChange (e) {
      var fileInput = e.target.files
      this.compressFile(fileInput)
    },
    compressFile (files) {
      for (let key in files) {
        if (key !== 'length' && key !== 'item') {
          let file = files[key]
          let type = file.type
          let vm = this

          if (['image/jpeg', 'image/jpg', 'image/png'].includes(type) && file.size >= 200000 && this.isCompress) {
            console.log('compressing file...');
            new Compressor(file, {
              quality: 0.4,
              convertSize: 400000,
              maxHeight: 500,
              success(res) {
                vm.upload(res)
              }
            })
          } else {
            vm.upload(file)
          }
        }
      }
    },
    checkType (files) {
      let arr = []
      for (let key in files) {
        if (key !== 'length' && key !== 'item') {
          let inputAccept = this.accept.split(',')
          let typeAccept = files[key].type.split('/')
          let match = inputAccept.some(v => {
            let app = v.split('/')
            if(app[0].trim() !== typeAccept[0].trim()) return false
            if(app[1].trim() == '*') return true
            if(app[1].trim() !== typeAccept[1].trim()) return false
            return true
          })
          arr.push(match)
        }
      }
      return arr.every(v => v)
    },
    async upload (file) {
      const formData = {
        filename: file.name,
        mimeType: file.type,
        size: file.size,
        width: 0,
        height: 0,
        acl: this.acl,
        folderId: this.folderId
      }

      const readImageSize = async (file) => {
        return new Promise((resolve, reject) => {
          let reader = new FileReader()
          reader.onload = (e) => {
            var img = new Image();

            img.onload = () => {
              resolve({
                width: img.width, 
                height: img.height
              })
            }

            img.src = e.target.result
          }
          reader.onerror = (err) => {
            reject(err)
          }

          reader.readAsDataURL(file)
        })
      }

      if (file.type.includes('image/')) {
        const resp = await readImageSize(file)
        formData.width = resp.width
        formData.height = resp.height
      }

      this.$apollo.mutate({
        mutation: CREATE_ASSET(this.templateType),
        variables: {
          assetType: this.assetType,
          input: formData
        }
      })
      .then(res => {
        const config = res.data.createAsset
        let formData = new FormData()

        formData.append('acl', this.acl)
        formData.append('Content-Type', file.type)
        Object.keys(config.fields).forEach(k => {
          formData.append(k, config.fields[k])
        })
        formData.append('file', file, file.name);

        axios.post(config.url, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(() => {
          this.$emit('change', null)
          this.$toasted.global.success("เพิ่มสำเร็จ")
        }).catch(() => {
          this.$toasted.global.error("ไม่สามารถอัพโหลดได้")
        })
      })
      .finally(() => {
        this.$refs.fileInput.value = null
      })
    }
  }
}
</script>

<style lang="css" scoped>
.image-width {
  width: 150px;
}

.dropbox {
  outline: 3px dashed grey; /* the dash box */
  outline-offset: -5px;
  color: dimgray;
  position: relative;
  cursor: pointer;
}

.dropbox p {
  font-size: 1.2em;
  text-align: center;
  padding: 15px;
  margin: auto;
}

.input-file {
  opacity: 1; /* invisible but it's there! */
  width: 0px;
  height: 0px;
  position: absolute;
  cursor: pointer;
  visibility: hidden;
}
</style>
