import gql from 'graphql-tag'


export const LIST_FOLDER_PATH = (templateType) => gql`query LIST_FOLDER_PATH ($assetType: String!, $folderId: Int!) {
  folders: list${templateType}FolderPath (assetType: $assetType, folderId: $folderId) {
    id type name parentId
  }
}`

const listResponse = `
  id type name parentId
`

export const LIST_FOLDER = (templateType) => gql`query LIST_FOLDER ($assetType: String!, $parentId: Int, $q: ${templateType}FolderFilterInput) {
  folders: list${templateType}Folder (assetType: $assetType, parentId: $parentId, q: $q) {${listResponse}}
}`

export const DETAIL_FOLDER = (templateType) => gql`query DETAIL_FOLDER ($assetType: String!, $folderId: Int!) {
  folder: detail${templateType}Folder (assetType: $assetType, folderId: $folderId) {${listResponse}}
}`

export const CREATE_FOLDER = (templateType) => gql`mutation CREATE_FOLDER ($assetType: String!, $input: ${templateType}FolderCreateInput!) {
  createFolder: create${templateType}Folder (assetType: $assetType, input: $input) {${listResponse}}
}`

export const UPDATE_FOLDER = (templateType) => gql`mutation UPDATE_FOLDER ($assetType: String!, $folderId: Int!, $input: ${templateType}FolderUpdateInput!) {
  updateFolder: update${templateType}Folder (assetType: $assetType, folderId: $folderId, input: $input) {${listResponse}}
}`

export const DESTROY_FOLDER = (templateType) => gql`mutation DESTROY_FOLDER ($assetType: String!, $folderId: Int!) {
  destroyFolder: destroy${templateType}Folder (assetType: $assetType, folderId: $folderId) {id}
}`