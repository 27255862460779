<template>
  <div>
    <div class="dashhead">
      <div class="dashhead-titles">
        <h6 class="dashhead-subtitle">{{subTitle}}</h6>
        <h3 class="dashhead-title">{{title}}</h3>
      </div>

      <div class="dashhead-toolbar">
        <div class=" dashhead-toolbar-item">
  
        </div>
      </div>
    </div>

    <hr class="my-3">

    <div class="row">
      <div class="col-12 mb-3">
        <Command 
          class="float-right"
          :templateType="templateType"
          :assetType="assetType"
          :assetIds="selected"
          @change="onCommand">
        </Command>
      </div>
      
      <FolderPath 
        class="col-12"
        :templateType="templateType"
        :assetType="assetType"
        :folderId.sync="parentId"
        @folder="refetch"
        @change="refetch">
      </FolderPath>
    </div>

    <div class="row">
      <div 
        v-for="folder in folders" 
        :key="folder.id"
        class="col-6 col-md-4 mb-3">
        <Folder 
          :folder="folder" 
          :templateType="templateType"
          :assetType="assetType"
          @parentId="setParentId"
          @change="refetch">
        </Folder>
      </div>
    </div>

    <Asset
      ref="asset"
      :templateType="templateType"
      :assetType="assetType"
      :folderId="parentId"
      :selected.sync="selected">
    </Asset>
  </div>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import { LIST_FOLDER } from './graph/folder'
import Folder from './Folder.vue'
import FolderPath from './FolderPath.vue'
import Asset from './Asset.vue'
import Command from './Command.vue'

export default {
  mixins: [retainMixin],
  metaInfo () {
    return {
      title: `${this.subTitle}-${this.title}`
    }
  },
  props: {
    templateType: {
      type: String,
      required: true
    },
    assetType: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      required: true
    },
    group: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      rKey: `ChronowoodthAsset${this.$form.capitalize(this.assetType)}List`,
      rFields: ['parentId'],
      folders: [],
      parentId: null,
      assetId: null,
      selected: []
    }
  },
  apollo: {
    folders: {
      query () {
        return LIST_FOLDER(this.templateType)
      },
      variables () {
        return {
          assetType: this.assetType,
          parentId: this.parentId,
          q: {
            orderBy: 'id'
          }
        }
      },
      fetchPolicy: 'no-cache'
    }
  },
  methods: {
    setParentId (v) {
      this.parentId = v
    },
    refetch () {
      this.$apollo.queries.folders.refetch()
    },
    onCommand () {
      this.selected = []
      this.$refs.asset.refetch()
    }
  },
  watch: {
    parentId: 'setRetaining'
  },
  created () {
    this.$store.commit('path/setCurrent', {to: this.$route, group: this.group})
  },
  components: {
    Folder,
    FolderPath,
    Asset,
    Command,
  }
}
</script>

<style lang="css" scoped>
</style>
