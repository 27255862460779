<template>
  <span class="">
    <slot v-bind="{toggle}"></slot>

    <b-modal 
      v-model="show" 
      :title="title" 
      size="lg"
      @ok="handleOk">
      <CommandFolderPath 
        :templateType="templateType"
        :assetType="assetType"
        :folderId.sync="folderId">
      </CommandFolderPath>

      <div class="row">
        <div 
          v-for="folder in folders" 
          :key="folder.id"
          class="col-6 col-md-4 mb-3">
          <div class="card pointer" @click="setFolderId(folder.id)">
            <div class="card-body">
              <span class="text-warning mr-2">
                <fa icon="folder"></fa>
              </span>
              <span class="card-text">
                {{ folder.name }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </span>
</template>

<script>
import { MOVE_ASSET } from './graph/asset'
import { LIST_FOLDER } from './graph/folder'
import retainMixin from '@/mixins/retain-mixin'
import CommandFolderPath from './CommandFolderPath.vue'

export default {
  mixins: [retainMixin],
  props: {
    templateType: {
      type: String,
      required: true
    },
    assetType: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    multiple: {
      type: Boolean,
      required: false
    },
    assetIds: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      rKey: `ChronowoodthAsset${this.$form.capitalize(this.assetType)}CommandFolder`,
      rFields: ['folderId'],
      show: false,
      folderId: null,
      folders: [],
    }
  },
  apollo: {
    folders: {
      query () {
        return LIST_FOLDER(this.templateType)
      },
      variables () {
        return {
          assetType: this.assetType,
          parentId: this.folderId,
          q: {
            orderBy: 'id'
          }
        }
      },
      fetchPolicy: 'no-cache'
    }
  },
  methods: {
    toggle () {
      this.show = !this.show
    },
    handleOk () {
      if (this.assetIds.length === 0) return

      this.$apollo.mutate({
        mutation: MOVE_ASSET(this.templateType),
        variables: {
          assetType: this.assetType,
          assetIds: this.assetIds,
          folderId: this.folderId
        }
      })
      .then(() => {
        this.$emit('change', null)
        this.show = false
      })
      .catch(this.$toasted.global.error)
    },
    setFolderId (v) {
      this.folderId = v
    },
    refetch () {
      this.$apollo.queries.folders.refetch()
    }
  },
  watch: {
    folderId: 'setRetaining',
  },
  components: {
    CommandFolderPath
  }
}
</script>

<style lang="css" scoped>
</style>
